import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { oneLine } from 'common-tags';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Content01, Modal, Price, Text } from '@/atoms';
import { Link as Enlace } from '@/atoms';

import { toArray } from '@/utils';
import { RootState } from '@/lib/store';
import { addToCart } from '@/lib/carts';
import { setTipoProducto } from '@/lib/typeElements';

import { TypeProductDescriptionProps } from './types';
import { ButtonVariant } from '@/atoms/button/types';

/**
 * ProductDescription
 */
export const ProductDescription: FunctionComponent<
  TypeProductDescriptionProps
> = ({
  className = '',
  title,
  description,
  link,
  price,
  moreInfo,
  list,
  advantages,
  inStock = true,
  product,
}: TypeProductDescriptionProps) => {
    const dispatch = useDispatch();
    const tipoSelección = useSelector((state: RootState) => state.typeElements);

    const [isModal, setIsModal] = useState(false);

    const giftOrNormal = () => product?.metafields?.custom?.gift?.value === true ? 'gift' : 'normal';

    const activaTipoProducto = () => {
      dispatch(setTipoProducto(giftOrNormal()));
      sessionStorage.setItem('typeElements', giftOrNormal());
    };

    useEffect(() => {
      dispatch(setTipoProducto(sessionStorage.getItem('typeElements') || ''));
    }, []);

    const carts = useSelector((state: RootState) => state.carts);

    return (
      <div className={`flex flex-col gap-5 md:gap-8 ${className}`}>
        <Content01
          {...(title && { title, typeTitle: 'h1' })}
          {...(description && { description })}
          {...(link && { link })}
        />

        <div className="flex flex-row gap-20 items-end border-b border-primary-200 md:border-none pb-4 md:pb-0">
          <div className="flex gap-1 items-end w-full md:w-auto flex-wrap">
            <Price
              regular={price.regular}
              {...(price.sale && { sale: price.sale })}
              position="below"
              size="large"
            />

            {moreInfo && (
              <Text className="text-body text-body--m text-text-secondary md:pb-1">
                {moreInfo}
              </Text>
            )}
          </div>

          <Button
            variant={ButtonVariant.PRIMARY}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={() => {
              if (
                giftOrNormal() === tipoSelección.value ||
                tipoSelección.value === ''
              ) {
                activaTipoProducto();
                if (
                  giftOrNormal() === 'gift' &&
                  carts.data.cart.lines.edges.length + 1 > 1
                ) {
                  setIsModal(true);
                } else {
                  dispatch(addToCart(product, 1));
                }
              } else {
                setIsModal(true);
              }
            }}
            disabled={!inStock}
          >
            AÑADIR AL CARRITO
          </Button>
        </div>

        {tipoSelección.value === 'gift' && (
          <Modal
            isOpen={isModal}
            closeModal={() => setIsModal(false)}
            className="w-6/12"
          >
            <div className="flex flex-col md:flex-column gap-20 md:gap-10">
              <div className="flex flex-col gap-6 md:gap-4">
                <span className="font-bold text-body text-body--l text-text-primary">
                  No podemos agregar el producto
                </span>
                <span className="text-body text-body--m text-text-primary">
                  Tienes un producto de regalo añadido a tu carrito, por lo que no
                  podemos añadir otro producto adicional. Para añadir un producto
                  diferente a tu pedido, vacía primero tu carrito.
                </span>
              </div>
              <div className="flex flex-col gap-5" />
              <Enlace
                className="justify-center md:justify-start"
                icon="right"
                variant="POSITIVE"
                href="/es/cesta"
              >
                Ir a la cesta
              </Enlace>
            </div>
          </Modal>
        )}

        {tipoSelección.value === 'gift' &&
          carts.data.cart.lines.edges.length > 1 && (
            <Modal
              isOpen={isModal}
              closeModal={() => setIsModal(false)}
              className="w-6/12"
            >
              <div className="flex flex-col md:flex-column gap-20 md:gap-10">
                <div className="flex flex-col gap-6 md:gap-4">
                  <span className="font-bold text-body text-body--l text-text-primary">
                    No podemos agregar el producto
                  </span>
                  <span className="text-body text-body--m text-text-primary">
                    Tienes un producto de regalo añadido a tu carrito, por lo que
                    no podemos añadir otro producto adicional. Para añadir un
                    producto diferente a tu pedido, vacía primero tu carrito.
                  </span>
                </div>
                <div className="flex flex-col gap-5" />
                <Enlace
                  className="justify-center md:justify-start"
                  icon="right"
                  variant="POSITIVE"
                  href="/es/cesta"
                >
                  Ir a la cesta
                </Enlace>
              </div>
            </Modal>
          )}

        {tipoSelección.value === 'normal' && (
          <Modal
            isOpen={isModal}
            closeModal={() => setIsModal(false)}
            className="w-6/12"
          >
            <div className="flex flex-col md:flex-column gap-20 md:gap-10">
              <div className="flex flex-col gap-6 md:gap-4">
                <span className="font-bold text-body text-body--l text-text-primary">
                  No podemos agregar el producto
                </span>
                <span className="text-body text-body--m text-text-primary">
                  Ya tienes un producto añadido a tu carrito, por lo que no
                  podemos añadir un producto de regalo. Para añadir un producto de
                  regalo, vacía primero tu carrito.
                </span>
              </div>
              <div className="flex flex-col gap-5" />
              <Enlace
                className="justify-center md:justify-start"
                icon="right"
                variant="POSITIVE"
                href="/es/cesta"
              >
                Vaciar carrito
              </Enlace>
            </div>
          </Modal>
        )}

        {list && (
          <div className="flex flex-col">
            {list.title && (
              <span className="text-body text-body--m text-primary-600 font-bold mb-4">
                {list.title}
              </span>
            )}

            {list.items && (
              <ul className="list-disc text-primary-600 ml-4 relative">
                {list.items.map((item, index: number) => (
                  <li
                    className="text-body text-body--m text-primary-600 mb-2"
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {advantages && (
          <div className="border-y border-primary-600 py-4 md:py-5">
            <div className="overflow-x-auto -mx-4 md:mx-0">
              <div className="flex justify-around gap-4 md:gap-2 min-w-max p-4 md:p-0">
                {toArray(advantages).map((child: ReactElement, index: number) => (
                  <child.type
                    {...child.props}
                    className={oneLine`
                    ${child.props?.className ?? ''}
                    flex-shrink-0 md:max-w-[100px]
                  `}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

ProductDescription.displayName = 'ProductDescription';
