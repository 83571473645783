import { classInjector } from '@/lib/utils';
import { FunctionComponent } from 'react';

import { TypeLineTableProps } from './types';

/**
 * LineTable es el componente básico de Table.
 */
export const LineTable: FunctionComponent<TypeLineTableProps> = ({
  name,
  value,
  className = '',
  children,
}: TypeLineTableProps) => (
  <div className={children ? '' : 'border-solid border-b border-secondary-200'}>
    <div
      className={`flex justify-between py-3 text-body text-body--m ${className}`}
    >
      <span>{name}</span>
      <span>{value}</span>
    </div>
    {children && classInjector('text-link text-link--s pl-4', children)}
  </div>
);
