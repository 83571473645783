import { FunctionComponent, ReactNode, createRef, useEffect } from 'react';
import { Options } from '@splidejs/splide';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import Image from 'next/image';

import { ArrowLeft, ArrowRight } from '@/icons';

import { TypeProductGalleryProps } from './types';

import '@splidejs/react-splide/css/core';

const PAGE = `xl:hidden mt-4 before:w-1 before:h-1 p-3 relative before:bottom-2.5 before:right-2.5 before:rotate-45 before:bg-primary-500 before:content:[''] before:absolute [&.is-active]:after:border-primary-500 [&.is-active]:after:content-[''] [&.is-active]:after:border [&.is-active]:after:rounded-full [&.is-active]:after:p-3 [&.is-active]:after:absolute [&.is-active]:after:top-1/2 [&.is-active]:after:left-1/2 [&.is-active]:after:transform [&.is-active]:after:-translate-y-1/2 [&.is-active]:after:-translate-x-1/2`;

/**
 * The main Splide component.
 */
export const mainRef = createRef<Splide>();

export const ProductGallery: FunctionComponent<TypeProductGalleryProps> = ({
  slides,
  className,
}: TypeProductGalleryProps) => {
  const hasMoreThanOneSlide = slides.length > 1;
  /**
   * The thumbnail Splide component.
   */
  const thumbsRef = createRef<Splide>();

  /**
   * Set the sync target right after the component is mounted.
   */
  useEffect(() => {
    if (
      mainRef.current &&
      thumbsRef.current &&
      thumbsRef.current.splide &&
      hasMoreThanOneSlide
    ) {
      mainRef.current.sync(thumbsRef.current.splide);
    }
  });

  const renderSlides = (className: string): ReactNode[] =>
    slides.map((slide, index) => (
      <SplideSlide
        key={`${slide.src}_${index}`}
        {...(className && { className })}
      >
        <Image
          priority
          src={slide.src}
          alt={slide.alt ?? ''}
          className="w-full h-full object-cover object-center absolute inset-0"
          sizes="100vw"
          fill
        />
      </SplideSlide>
    ));

  const mainOptions: Options = {
    type: slides.length === 1 ? 'slide' : 'loop',
    perPage: 1,
    perMove: 1,
    gap: '1rem',
    pagination: hasMoreThanOneSlide,
    arrows: hasMoreThanOneSlide,
    classes: {
      page: PAGE,
    },
    lazyLoad: 'sequential',
  };

  const THUMBS_NUM_SLIDES = 3;
  const THUMBS_GAP = '0.5rem';
  const THUMBS_HEIGHT = 80;
  const thumbsOptions: Options = {
    type: 'slide',
    rewind: true,
    gap: THUMBS_GAP,
    pagination: false,
    height: `calc(${THUMBS_NUM_SLIDES} * ${THUMBS_HEIGHT}px + ${THUMBS_GAP} * (${THUMBS_NUM_SLIDES} - 1))`,
    fixedWidth: THUMBS_HEIGHT,
    fixedHeight: THUMBS_HEIGHT,
    heightRatio: 1,
    cover: true,
    focus: 'center',
    isNavigation: true,
    arrows: false,
    updateOnMove: true,
    lazyLoad: 'sequential',
    direction: 'ttb',
  };

  return (
    <div className={`xl:pb-10 ${className}`}>
      <div className="relative">
        <Splide
          options={mainOptions}
          ref={mainRef}
          className="relative"
          hasTrack={false}
        >
          <SplideTrack>{renderSlides('aspect-square')}</SplideTrack>

          <div className="splide__arrows mx-2 text-primary-600 hidden xl:flex flex-row justify-between absolute top-1/2 left-0 right-0 transform -translate-y-1/2">
            <button className="splide__arrow splide__arrow--prev">
              <ArrowLeft width={24} height={24} />
            </button>

            <button className="splide__arrow splide__arrow--next">
              <ArrowRight width={24} height={24} />
            </button>
          </div>
        </Splide>

        {hasMoreThanOneSlide && (
          <div className="absolute -bottom-10 left-0">
            <Splide
              options={thumbsOptions}
              ref={thumbsRef}
              className="relative hidden xl:block"
            >
              {renderSlides('opacity-40 [&.is-active]:opacity-100')}
            </Splide>
          </div>
        )}
      </div>
    </div>
  );
};

ProductGallery.displayName = 'ProductGallery';
