import { FunctionComponent } from 'react';

import { Text } from '@/atoms';

import { TypeContentInfoProps } from './types';

/**
 * ContentInfo
 */
export const ContentInfo: FunctionComponent<TypeContentInfoProps> = ({
  className = '',
  title,
  children,
}: TypeContentInfoProps) => (
  <div className={className}>
    {title && (
      <Text className="text-body text-body--m font-bold mb-3" as="h2">
        {title}
      </Text>
    )}
    {children}
  </div>
);

ContentInfo.displayName = 'ContentInfo';
