import { Fragment, FunctionComponent, ReactNode } from 'react';

import { Text } from '@/atoms';

import { toArray } from '@/lib/utils';

import { TypeNutritionInfoTableProps } from './types';

/**
 * NutritionInfoTable
 */
export const NutritionInfoTable: FunctionComponent<
  TypeNutritionInfoTableProps
> = ({
  className = '',
  title,
  children,
  isLine,
}: TypeNutritionInfoTableProps) => (
  <div className={className}>
    <Text className="text-subtitle text-subtitle--l mb-8" as="h2">
      {title}
    </Text>
    <div className="flex flex-col gap-6">
      {isLine
        ? toArray(children).map((child: ReactNode, index: number) => (
            <Fragment key={index}>
              {child}
              <hr className="w-full text-secondary-200 last-of-type:hidden" />
            </Fragment>
          ))
        : children}
    </div>
  </div>
);
