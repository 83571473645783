import ContentInfo from '@/molecules/content-info';

import { NutritionInfoTable } from './NutritionInfoTable';

export type TypeNutritionInfoTable = typeof NutritionInfoTable & {
  Info: typeof ContentInfo;
};

(NutritionInfoTable as TypeNutritionInfoTable).Info = ContentInfo;

ContentInfo.displayName = 'NutritionInfoTable.Info';

export type { TypeNutritionInfoTableProps } from './types';
export type { TypeContentInfoProps } from '@/molecules/content-info';

export default NutritionInfoTable as TypeNutritionInfoTable;
