import { AboutProduct } from './AboutProduct';

import ContentImage from '@/molecules/content-image';
import MoreInfo from '@/molecules/more-info';

export type TypeAboutProduct = typeof AboutProduct & {
  Text: typeof MoreInfo;
  TextWithImage: typeof ContentImage;
};

(AboutProduct as TypeAboutProduct).Text = MoreInfo;
(AboutProduct as TypeAboutProduct).TextWithImage = ContentImage;

MoreInfo.displayName = 'AboutProduct.Text';
ContentImage.displayName = 'AboutProduct.TextWithImage';

export type { TypeAboutProductProps } from './types';
export default AboutProduct as TypeAboutProduct;
