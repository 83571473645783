import { FunctionComponent } from 'react';

import { TypeAboutProductProps } from './types';

/**
 * AboutProduct
 */
export const AboutProduct: FunctionComponent<TypeAboutProductProps> = ({
  className = '',
  children,
}: TypeAboutProductProps): JSX.Element => (
  <div className={`flex flex-col gap-8 md:gap-16 ${className}`}>{children}</div>
);

AboutProduct.displayName = 'AboutProduct';
