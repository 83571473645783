import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';
import Link from 'next/link';

import { Button } from '@/atoms';

import { TypeSocialMedia, TypeSocialMediaProps } from './types';
import { ButtonVariant } from '@/atoms/button/types';
import parse from "html-react-parser";

export const SocialMedia: FunctionComponent<TypeSocialMediaProps> = ({
  className = '',
  title,
  socialMedias,
  isColumn,
  position,
  variant,
  outline,
}: TypeSocialMediaProps) => (
  <div
    className={oneLine`
      flex flex-col gap-4
      ${isColumn ? 'xl:flex-col' : 'xl:flex-row'}
      ${position === 'start'
        ? 'justify-start items-start'
        : 'justify-center items-center'
      }
      ${className}
    `}
  >
    {title && (
      <span
        className={oneLine`
        tracking-[0.2rem] text-caption text-caption--s
        ${variant === 'white' ? 'text-text-alternative' : 'text-text-secondary'}
      `}
      >
        {title}
      </span>
    )}

    <div className="flex flex-row gap-4">
      {socialMedias?.map((socialMedia: TypeSocialMedia, index: number) => (
        <Link
          href={socialMedia.href}
          prefetch={false}
          passHref
          key={`${index}_${socialMedia.href}`}
          legacyBehavior
        >
          <Button
            as="a"
            variant={ButtonVariant.ICON}
            outline={outline}
            rel="noopener noreferrer"
            target="_blank"
            className="!p-2"
            aria-label={socialMedia.href}
          >
            {socialMedia.image ? parse(socialMedia.image) : <socialMedia.Icon width={24} height={24} /> }          
          </Button>
        </Link>
      ))}
    </div>
  </div>
);

SocialMedia.displayName = 'SocialMedia';
