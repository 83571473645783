import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { toArray } from '@/lib/utils';
import { oneLine } from 'common-tags';
import { TypeTabGroupProps } from './types';
import { useRouter } from 'next/router';

/**
 * TabGroup
 */
export const TabGroup: FunctionComponent<TypeTabGroupProps> = ({
  className = '',
  children,
  tabs,
}: TypeTabGroupProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const router = useRouter();
  useEffect(() => {setSelectedIndex(0)}, [router.asPath]);
  return <Tab.Group as="div" className={className} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
    <Tab.List className="overflow-x-auto -mx-4 md:mx-0">
      <div className="flex justify-center gap-2 md:gap-6 min-w-max p-4 md:p-0">
        {tabs.map((item: string, index: number) => (
          <Tab as={Fragment} key={index}>
            {({ selected }) => (
              <button
                className={oneLine`
                  flex-shrink-0 border rounded-full py-2 px-4 focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0
                  ${selected ? 'text-primary-500 border-primary-500' : ''}
                `}
              >
                {item}
              </button>
            )}
          </Tab>
        ))}
      </div>
    </Tab.List>

    <Tab.Panels className="mt-8 md:mt-12">
      {toArray(children).map(
        (child, index) =>
          child && (
            <Tab.Panel key={index} unmount={false}>
              {child}
            </Tab.Panel>
          )
      )}
    </Tab.Panels>
  </Tab.Group>
};

TabGroup.displayName = 'TabGroup';
